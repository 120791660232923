input {
  margin-bottom: 15px;
  height: 30px;
  text-indent: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  display: block;
  justify-content: center;

}

.signUp {
  text-align:center;
  padding-bottom: 20px;
  text-decoration: none;
  color: black;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
  }

.formLogin {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  border: 1px solid #444;
  border-radius: 5px;
  width: 20%;
  margin: auto;
  margin-top: .2in;
}

button {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

h2{
  text-align:center;
}

p{
  text-align:center;
}




