/* LabPages.css */

/* Main container for the LabsPage */
.labs-page-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading style */
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Lab item style */
  .lab-item {
    border: 1px solid #ccc;
    text-decoration: none;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
  }
  
  /* Lab name style */
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  /* Lab category style */
  p {
    margin: 5px 0;
  }
  
  /* Strong style for Lab Field and Professor categories */
  .lab-item strong {
    font-weight: bold;
  }
  
  /* Remove default list styles from labs-list */
  .labs-list {
    list-style-type: none;
    padding: 0;
  }

  .Linked-Block{
    border: none;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    color:black;

}
  