.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .signup-form h1 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .signup-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .signup-form button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .signup-form button:hover {
    background-color: #45a049;
  }
  
  .signup-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .signup-form .error-message {
    color: red;
    margin-bottom: 10px;
    text-align: center;
  }
  