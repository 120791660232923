

.link-button {
    display: inline-block;
    text-decoration: none;
    border: solid;
    padding: .75rem 1.375rem;
    color: initial;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    transition: transform .1s ease-in-out;
    margin: 1in;
    background-color: rgb(66, 127, 206); 
}

.link-button:hover {
    transform: translate(1px, 1px);
}

.button-div{
    text-align: center;
}

.strip{
    position: relative;
    margin-top: 1in;
    width: 100%; 
    height: 5in;
    background-color: rgb(76, 188, 240);
}
.quoteBox{
    position: relative;
    width: 50%;
    justify-content: center;
    margin: 0 auto;
}

.quote1 {
    position: absolute; /* Position the paragraph absolutely within the container */
    top: 70px;
    left: 100px;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.25;
    font-style: italic;
    color: #444;
    font-family: Arial, Helvetica, sans-serif;
}
.quote2 {
    position: absolute; /* Position the paragraph absolutely within the container */
    bottom: 70px;
    right: 100px;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.25;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    color: #444;
}





