.slogan {
    padding: 20px;
    text-align: center;
    padding-bottom: 1in;
  }

.slogan p {
    font-size: 16px;
    text-align: center;
    color: #333333;
  }
