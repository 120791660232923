.Comment{
  margin-bottom: 5px;
  height: 100px;
  border: 1px solid #444;
  border-radius: 5px;
  display: block;
  margin-bottom: 10px;
    resize: none;  

  /* justify-content: center; */
  width: 3in;
}

  textarea  
  {  
    text-decoration: none;
    font-weight: 800;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 10px;
    color: #444;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;   
  }

input {
    margin-bottom: 5px;
    height: 30px;
    border: 1px solid #444;
    border-radius: 5px;
    display: block;
    margin-bottom: 10px;
    
    /* justify-content: center; */
    width: 3in;
  }
  
  .signUp {
    margin-top: 2px;
    padding-bottom: 20px;
    text-decoration: none;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    }


  .formLogin {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    /* justify-content: center; */
    border: 1px solid #444;
    border-radius: 5px;
    width: 25%;
    margin: auto;
    margin-top: .2in;
  }

  
  button {
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
  }
  
  h2{
    text-align:center;
  }
  
  p{
    text-align:left;
  }

  .stars{
    margin-bottom: 20px;
  }
  
  
  
  
  