body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
}
.container {
    max-width: 90%;
    margin: 15px auto;
    padding: 0px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}
h2 {
    font-size: 18px;
    margin-bottom: 7px;
    color: #333;
}
p {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 0px;
    color: #555;
}
.badge {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px;
    /* background-color: #970ff1; */
    color: #fff;
    border-radius: 5px;
    font-size: 48px;
    font-weight: bold;
    margin-right: 20px;
    width: 60px;
}
.text {
    flex: 1;
    font-size: 20px;
    line-height: 1.5;
    color: #333;
}
.name-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #888;
    margin-top: 10px;
}