.strip2{
    width: 100%; height: .5in;
    background-color: rgb(98, 102, 103);
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.footerText{
    color: whitesmoke;
    text-align: center;
}