
header {
    background-color: rgb(119, 116, 116);
    color: rgb(255, 255, 255);
    height: 75px;
    display: flex;
    align-items: center;
}

.site-logo {
    color: black;
    margin-right: auto;
    margin-left: .5in;
    font-weight: 900;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;

}

.addLab{
    /* height: 50px; */
    color: black;
    background-color: rgb(55, 160, 209);
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    /* padding-bottom: 10px; */
    width: 150px;
    margin-right: 30px;
}

.request{
    text-align: center;
}

.buttonBox{
    display: flex;
    height: 100%;
}

img {
    margin: auto;
    height: 17px;
    margin-right: 8px;
  }